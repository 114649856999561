import { merge } from 'lodash';
import ReturnsApi from '@/util/api/returns';
import { joinChannel } from '@/util/helpers/websocket';
import router from '@/router';
import utils from '@/util/mixins/adminUtil';

const request = new ReturnsApi();

const filterUsers = (users, currentUser) => {
  return users.filter((user) => {
    const isCurrentUser = user.id === currentUser.id;
    const isLoop = user.email.includes('@loopreturns.com') || user.email.includes('@xariable.com');
    return !isCurrentUser && !isLoop;
  });
};

// This regex looks for matches on commonly used malicious characters such as data:text, <? ?> in the case of PHP, <script> tags, etc.
const validate = (input) => {
  return input.match(
    /(data:text|<(.*?)\s*%(.*?)%>|<\?(.*?)\?>|<(.*?)\s*script(.*?)<\/script>)/gm);
};

export default {
  namespaced: true,
  state: {
    text: {},
    returnData: null,
    usesRestock: false,
    autoRestock: false,
    reasons: {},
    activeUsers: [],
    qcReasons: [],
    editSessionId: '',
    editIndex: 0,
    editState: {},
    editOriginalState: {},
    editLoading: false,
    ixLoading: false,
    refundMethodModalOpen: false,
    refundMethodModalLoading: false,
    editFeeModalOpen: false,
    editFeeModalLoading: false,
    returnUpdatedEventFromCurrentUser: false,
    recentlyChargedItemIds: [],
  },
  mutations: {
    setProperty(state, { key, value }) {
      state[key] = value;
    },
    updateReturn(state, payload) {
      state.returnData = payload;
    },
    addNote(state, note) {
      state.returnData.notes = [
        note,
        ...state.returnData.notes
      ];
    },
    removeNote(state, { id }) {
      state.returnData.notes = state.returnData.notes
        .filter(note => note.id !== id);
    },
    updateLineItem(state, { id, data }) {
      const lineItems = state.returnData.order_line_items.map(item => {
        if (item.id === id) {
          return merge({}, item, data);
        }
        return item;
      });

      state.returnData = {
        ...state.returnData,
        order_line_items: lineItems
      };
    },
    updateAddress(state, { address }) {
      state.returnData.address = address;
    },
    setActiveUsers(state, users) {
      state.activeUsers = users;
    },
    updateTransaction(state, { transaction, chargedItemIds }) {
      const transactionsList = state.returnData.transactions_list.map((item) => {
        return (item.id === transaction.id) ? transaction : item;
      });
      state.returnData = {
        ...state.returnData,
        transactions_list: transactionsList,
        transactions: {
          ...state.returnData.transactions,
          refunds: transactionsList.flatMap(tx => tx.refunds),
        }
      };
      state.recentlyChargedItemIds.push(...chargedItemIds);

      if (state.editState?.transactions_list?.length) {
        state.editState = {
          ...state.editState,
          transactions_list: state.editState.transactions_list.map((item) => {
            if (item.id === transaction.id) {
              return transaction;
            }

            return item;
          }),
        };
      }
    },
  },
  actions: {
    clearReturnData({ commit }) {
      commit('setProperty', { key: 'returnData', value: {} });
      commit('setProperty', { key: 'editSessionId', value: '' });
      commit('setProperty', { key: 'editIndex', value: 0 });
      commit('setProperty', { key: 'editState', value: {} });
      commit('setProperty', { key: 'editOriginalState', value: {} });
    },
    setReturnUpdatedEventFromCurrentUser({ commit }, value) {
      // this is used to suppress return updated events that were initiated by the
      // user who is currently editing a return
      commit('setProperty', { key: 'returnUpdatedEventFromCurrentUser', value });
    },
    setRefundMethodModalOpen({ commit }, value) {
      commit('setProperty', { key: 'refundMethodModalOpen', value });
    },
    setRefundMethodModalLoading({ commit }, value) {
      commit('setProperty', { key: 'refundMethodModalLoading', value });
    },
    setEditFeeModalOpen({ commit }, value) {
      commit('setProperty', { key: 'editFeeModalOpen', value });
    },
    setEditFeeModalLoading({ commit }, value) {
      commit('setProperty', { key: 'editFeeModalLoading', value });
    },
    resetRegenerateLabelStatus({ commit, state }) {
      const { editState } = state;
      const { breakdown } = editState;

      breakdown.label = null;

      const newEditState = {
        ...editState,
        breakdown,
      };

      commit('setProperty', {
        key: 'editState',
        value: newEditState,
      });

      commit('setProperty', {
        key: 'editOriginalState',
        value: newEditState,
      });
    },
    async releaseAuthorization({ commit, state }, { transactionId }) {
      commit('setProperty', { key: 'ixLoading', value: true });

      try {
        const res = await request.transactions.release(state.returnData.id, transactionId);
        commit('updateTransaction', { transaction: res, chargedItemIds: [] });
        commit('setProperty', { key: 'ixLoading', value: false });
      } catch (error) {
        console.error(error);
        const toast = error.response?.data?.errors[0] ?? `Whoops, we couldn't release this authorization.`;
        commit('setToast', {
          message: toast,
          type: 'error',
        }, { root: true });
        commit('setProperty', { key: 'ixLoading', value: false });
      }
    },
    async chargeAuthorization({ commit, state }, { transactionId, orderLineItemIds }) {
      commit('setProperty', { key: 'ixLoading', value: true });

      try {
        const res = await request.transactions.charge(state.returnData.id, transactionId, orderLineItemIds);
        commit('updateTransaction', { transaction: res, chargedItemIds: orderLineItemIds });
        const formattedAmount = utils.methods.money(res.lastAction.amount, res.lastAction.currency);
        commit('setToast', {
          message: `Success! A ${formattedAmount} charge has been issued.`,
          type: 'success',
        }, { root: true });
        commit('setProperty', { key: 'ixLoading', value: false });

        return true;
      } catch (error) {
        console.error(error);
        const toast = error.response?.data?.errors[0] ?? `Whoops, we couldn't charge this authorization.`;
        commit('setToast', {
          message: toast,
          type: 'error',
        }, { root: true });
        commit('setProperty', { key: 'ixLoading', value: false });

        return false;
      }
    },
    async refundTransaction({ commit, state }, { transactionId, orderLineItemIds }) {
      commit('setProperty', { key: 'ixLoading', value: true });

      try {
        const res = await request.transactions.refund(state.returnData.id, transactionId, orderLineItemIds);
        commit('updateTransaction', { transaction: res, chargedItemIds: [] });
        const formattedAmount = utils.methods.money(res.lastAction.amount, res.lastAction.currency);
        commit('setToast', {
          message: `Success! A ${formattedAmount} refund has been issued.`,
          type: 'success',
        }, { root: true });
        commit('setProperty', { key: 'ixLoading', value: false });

        return true;
      } catch (error) {
        console.error(error);
        const toast = error.response.data.errors[0] ?? `Whoops, we couldn't refund this charge.`;
        commit('setToast', {
          message: toast,
          type: 'error',
        }, { root: true });
        commit('setProperty', { key: 'ixLoading', value: false });

        return false;
      }
    },
    // Send an edit intent, applying an edit to an edit session
    async sendEditIntent({ commit, state }, { returnId, name, requestData }) {
      try {
        commit('setProperty', { key: 'editLoading', value: true });
        const update = await request.edit.update(returnId, state.editSessionId, name, requestData);
        commit('setProperty', { key: 'editIndex', value: update.index });
        commit('setProperty', { key: 'editSessionId', value: update.sessionId });
        commit('setProperty', { key: 'editState', value: update });
        commit('setProperty', { key: 'editLoading', value: false });
        Promise.resolve(update);
      } catch (err) {
        console.error(err);

        if (err.response?.data?.error) {
          commit('setToast', {
            message: err.response.data.error,
            type: 'error',
          }, { root: true });
        } else {
          commit('setToast', {
            message: `Whoops, we weren't able to complete this edit.`,
            type: 'error',
          }, { root: true });
        }

        commit('setProperty', { key: 'editLoading', value: false });
        Promise.reject(err);
      }
    },
    // Send an edit store intent, saving all current edits for a session
    async sendEditStore({ commit, state }, { returnId }) {
      try {
        commit('setProperty', { key: 'editLoading', value: true });
        const store = await request.edit.store(returnId, state.editSessionId, state.editState.state, state.editState.breakdown);
        commit('setProperty', { key: 'editSessionId', value: '' });
        commit('setProperty', { key: 'editState', value: store });
        commit('setProperty', { key: 'editLoading', value: false });
        commit('setProperty', { key: 'editOriginalState', value: store });
        Promise.resolve(store);
      } catch (err) {
        console.error(err);
        commit('setProperty', { key: 'editLoading', value: false });
        commit('setToast', {
          message: `Whoops, we weren't able to store your edit.`,
          type: 'error',
        }, { root: true });
        Promise.reject(err);
      }
    },
    // Send an edit cancel intent, removing all edits and the edit session
    async sendEditCancel({ commit, state }, { returnId }) {
      try {
        commit('setProperty', { key: 'editLoading', value: true });
        const cancel = await request.edit.destroy(returnId, state.editSessionId);
        commit('setProperty', { key: 'editIndex', value: 0 });
        commit('setProperty', { key: 'editSessionId', value: '' });
        commit('setProperty', { key: 'editState', value: state.editOriginalState });
        commit('setProperty', { key: 'editLoading', value: false });
        Promise.resolve(cancel);
      } catch (err) {
        console.error(err);
        commit('setToast', {
          message: `Whoops, we weren't able to cancel your edit.`,
          type: 'error',
        }, { root: true });
        commit('setProperty', { key: 'editLoading', value: false });
        Promise.reject(err);
      }
    },
    async getPageData({ state, commit, rootGetters }, route) {
      // Connect to the presence channel for this return
      try {
        const currentUser = rootGetters.user;
        joinChannel(`return.${route.params.id}`)
          .here((users) => {
            commit('setActiveUsers', filterUsers(users, currentUser));
          }).joining((user) => {
            commit('setActiveUsers', filterUsers([
              ...state.activeUsers,
              user
            ], currentUser));
          })
          .leaving((user) => {
            commit('setActiveUsers', filterUsers(state.activeUsers.filter((active) => {
              return active.id !== user.id;
            }), currentUser));
          });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Unable to establish socket connection');
      }

      const { id } = route.params;

      const pageLoadRequests = [
        request.get(id)
          .then(res => {
            commit('updateReturn', res.data);
          })
          .catch(err => {
            console.error(err);
            router.push({ name: '404' });
          }),
        request.getPage()
          .then(res => {
            commit('setProperty', { key: 'stateData', value: res.data.states }, { root: true });
            commit('setProperty', { key: 'provinceData', value: res.data.provinces }, { root: true });
            commit('setProperty', { key: 'usesRestock', value: res.data.uses_restock });
            commit('setProperty', { key: 'autoRestock', value: res.data.auto_restock });
          }),
        ...(rootGetters.getSetting('EDIT_RETURN_MERCHANTS') ? [
          request.edit.initialize(id).then(res => {
            commit('setProperty', { key: 'editState', value: res });
            commit('setProperty', { key: 'editOriginalState', value: res });
          })
        ] : [])
      ];

      await Promise.all(pageLoadRequests);
    },
    async rejectLineItems({ commit, state }, { returnId, lineItemIds }) {
      try {
        const reject = await request.rejectLineItems(returnId, lineItemIds);

        const orderLineItemStatuses = lineItemIds.map((lineItemId) => {
          return [...(state.returnData.order_line_items.find(orderLineItem => orderLineItem.id === lineItemId).status)];
        });

        let updatedReviewStatuses;
        if (orderLineItemStatuses.length) {
          const reviewStatuses = orderLineItemStatuses.map((orderLineItemStatus) => {
            return orderLineItemStatus.find(item => item.status === 'review' || {});
          });

          updatedReviewStatuses = reviewStatuses.map((reviewStatus) => {
            return { ...reviewStatus, status: 'reject' };
          });
        }

        lineItemIds.forEach((lineItemId, index) => {
          commit('updateLineItem', {
            id: lineItemId,
            data: {
              status: orderLineItemStatuses[index].map((status) => 
                status.id === updatedReviewStatuses[index].id ? updatedReviewStatuses[index] : status),
            }
          });
        });
        Promise.resolve(reject);
      } catch (err) {
        console.error(err);
        commit('setToast', {
          message: `Whoops, we weren't able to reject the line item(s).`,
          type: 'error',
        }, { root: true });
        Promise.reject(err);
      }  
    },
    async rejectComment({ commit }, { returnId, comment, lineItemId }) {
      try {
        const invalidComment = validate(comment);
        if (invalidComment) {
          throw new Error('Malicious characters detected in comment');
        }

        await request.comments(returnId, comment, lineItemId);

        commit('updateLineItem', {
          id: lineItemId,
          data: {
            comment
          }
        });
      } catch (err) {
        console.error(err);
        commit('setToast', {
          message: `Whoops, we weren't able to save your comment.`,
          type: 'error',
        }, { root: true });
      }  
    },
    async sendRejectNotification({ commit }, { returnId }) {
      try {
        await request.sendRejectNotification(returnId);
        Promise.resolve();
      } catch (err) {
        console.error(err);
        commit('setToast', {
          message: `Whoops, we weren't able to send the rejection notification.`,
          type: 'error',
        }, { root: true });
        throw new Error('Error sending reject notification');
      }
    },
    async acceptReturn({ commit }, { returnId }) {
      try {
        await request.acceptReturn(returnId);
        const newData = await request.get(returnId);

        commit('setProperty', {
          key: 'returnData',
          value: newData.data
        });

        Promise.resolve(newData);
      } catch (err) {
        console.error(err);
        commit('setToast', {
          message: `Whoops, we weren't able to accept this return.`,
          type: 'error',
        }, { root: true });
        Promise.reject(err);
      }
    }
  },
  getters: {
    returnData: state => state.returnData,
    lineItemData: (state) => (lineItemId) => {
      return state.returnData?.order_line_items?.filter(item => item.id === parseInt(lineItemId));
    }
  }
};
